<template>
  <div class="btn_container"
    :class="{ fixed: containerFixed }"
    :style="{height: btnHeight+'px'}">
      <div class="btn_bottom_wrap fixed" ref="buttons"
        :class="{ upper: isScrollToTopUpper }"
      >
        <slot name="buttons"></slot>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
/**
 * 더보기 Button 컴포넌트
 */
export default {
  name: 'FixedButton',
    data() {
    return {
      isScrollToTopUpper: false,
      footerOffsetTop_data: 0,
      btnHeight: 0,
    };
  },
  props: {
    isFooter: {
      type: Boolean,
      default: false,
    },
    footerOffsetTop: {
      type: Number,
      default: 874,
    },
  },
  created() {
     this.footerOffsetTop_data = this.$store.getters.getHeight;
  },
  computed: {
    ...mapGetters({
       myState: 'getHeight',
    }),
    containerFixed() {
      const v = !this.isFooter;
      return v;
    },
  },
  mounted() {
    this.btnHeight = this.$refs.buttons.clientHeight;
    window.addEventListener('scroll', this.onWindowScroll);
    this.$store.commit('setFixBtnHeight', this.btnHeight);
  },
  watch: {
    myState(val) {
      this.footerOffsetTop_data = val;
    },
  },
  methods: {
    onWindowScroll() {
      if (this.isFooter === false) return;
      const windowScrollY = window.scrollY || window.pageYOffset || 0; // ie11
      const windowInnerHeight = window.innerHeight;
      const t = this.footerOffsetTop_data - this.btnHeight;
      // footer영역에서 scroll-top 버튼 footer 위로 고정
      this.isScrollToTopUpper = windowScrollY >= (t - windowInnerHeight);
    },
  },
  destroyed() {
    // scroll event : https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
    window.removeEventListener('scroll', this.onWindowScroll);
  },
};
</script>


<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<MoreButton
  @click="showAlert('좀더 보여드립니다.')"
/>
```
</docs>
