<template>
  <div class="tab-contents-wrap">
    <div class="title-wrap">
      <h2 class="title">{{eventInfo.name}}</h2>
      <div class="header-info">
        <ul>
          <li class="date">
            {{ getDateString(eventInfo.startDate.replace(/\./g, '-'), eventInfo.endDate.replace(/\./g, '-')) }}
          </li>
          <li class="date">
            <template>
              {{eventInfo.placeName || eventInfo.placeDetail}}
            </template>
          </li>
        </ul>
      </div>
    </div>
    <template v-if="statusType === 1" >
      <div class="form-container">
        <ValidationObserver ref="form">
        <form>
          <fieldset>
            <legend>{{$t('content.event.Event.txt33')}}</legend>
            <ul>
              <li class="form-row">
                <ValidationProvider
                  :rules="{ required: true, phone: true }"
                  :name="$t('content.event.Event.txt34')"
                  v-slot="{ errors }"
                >
                  <TelInput
                    v-model="phone"
                    :label="$t('content.event.Event.txt34')"
                    :placeholder="$t('content.event.Event.txt35')"
                    :disabled="!enabledInput"
                    :class="{'error':errors[0]}"
                    required
                    maxlength="13"
                  />
                  <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                </ValidationProvider>
                <div class="checkbox-container">
                  <template v-if="enabledInput">
                    <BaseCheckbox
                      v-model="phoneInserted"
                    >{{$t('content.event.Event.txt36')}}
                    </BaseCheckbox>
                  </template>
                </div>
              </li>
              <li class="form-row">
                <BaseTextarea
                  v-model="memo"
                  :label="$t('content.event.Event.txt37')"
                  size="large"
                  :placeholder="$t('content.event.Event.txt38')"
                />
              </li>
              <li class="form-row">
                <div class="input-title">
                  {{$t("signUp.title.agreement")}}
                </div>
                <div class="terms-area">
                  <BaseCheckbox
                    v-model="agreed"
                    required
                  >{{$t('content.event.Event.txt43')}}
                  </BaseCheckbox>
                  <div class="terms-container">
                    <ol>
                      <li>{{$t('content.event.Event.txt39')}}</li>
                      <li>{{$t('content.event.Event.txt40')}}</li>
                      <li>
                        {{$t('content.event.Event.txt41')}}
                        <div class="period" v-html="$t('content.event.Event.txt42')">
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </li>
            </ul>
          </fieldset>
        </form>
        </ValidationObserver>
      </div>
      <!-- 버튼 -->
      <FixedButton :isFooter="isFooter">
        <template slot="buttons">
          <a href="#" class="btn-ghost" @click="goBack">Back</a>
          <button @click="apply" :disabled="!agreed" class="btn-basic">{{$t('content.event.Event.txt44')}}</button>
        </template>
      </FixedButton>
      <!-- // 버튼 -->
    </template>
    <template v-else-if="statusType === 2">
      <!-- 이벤트 신청 성공결과 -->
      <div class="reservation-result-wrap">
          <div class="reservation-result" >
              <dl class="completed">
                  <dt>{{$t('content.event.Event.txt45')}}</dt>
                  <dd class="desc">
                  </dd>
              </dl>
          </div>
      </div>
      <div class="btn_bottom_wrap fixed perspective">
        <button class="btn-ghost" @click="goEventCancel">{{$t('content.event.Event.txt46')}}</button>
        <button class="btn-basic" @click="goEventHistory">{{$t('content.event.Event.txt47')}}</button>
      </div>
      <!-- // 이벤트 신청 성공결과 -->
    </template>
    <template v-else>
      <!-- 이벤트 신청 에러결과 -->
      <div class="reservation-result-wrap">
          <div class="reservation-result">
              <dl class="failed">
                  <dt>{{$t('content.event.Event.txt48')}}</dt>
                  <dd class="desc" v-html="errMsg">
                  </dd>
              </dl>
          </div>
      </div>
      <!-- // 이벤트 신청 에러결과 -->
      <div class="btn-wrap perspective">
        <button class="btn-basic" @click="goConfirm">{{$t('content.event.Event.txt47')}}</button>
      </div>
    </template>
  </div>
</template>

<script>
import ApplyStep1 from '@/views/event/ApplyStep1.vue';
import FixedButton from '@/components/base/FixedButton.vue';

export default {
  name: 'MobileEventApplyStep1',
  extends: ApplyStep1,

  components: {
    FixedButton,
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    this.phone = this.userInfo.phone;
    this.enabledInput = (this.phone === '' || !this.phone);

    // footer 유무 설정
    this.setFooter(false);
  },
};
</script>
